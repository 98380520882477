import React, {useState, useEffect} from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Text,
	Box,
	VStack,
	Stepper,
	Step,
	StepIndicator,
	StepStatus,
	StepIcon,
	StepNumber,
	StepTitle,
	StepDescription,
	StepSeparator,
} from '@chakra-ui/react';
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Puddleglum} from 'puddleglum';

interface DepositPaymentModalProps {
	isOpen: boolean;
	onClose: () => void;
	depositAmount: number;
}

const stripePromise = loadStripe((window as any).stripe_key);

function CheckoutForm({onBack}: {onBack: () => void}) {
	const stripe = useStripe();
	const elements = useElements();
	const [processing, setProcessing] = useState(false);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!stripe || !elements) return;

		setProcessing(true);
		const returnUrl: string = `${window.location.origin}/employer/onboarding/deposit/complete`;
		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: returnUrl,
			},
		});

		if (result.error) {
			console.error(result.error);
			setProcessing(false);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Box mb={4}>
				<PaymentElement />
			</Box>
			<ModalFooter gap={3} px={0}>
				<Button variant='outline' onClick={onBack}>
					Back
				</Button>
				<Button type='submit' colorScheme='blue' isLoading={processing}>
					Pay Deposit
				</Button>
			</ModalFooter>
		</form>
	);
}

export default function DepositPaymentModal({
	isOpen,
	onClose,
	depositAmount,
}: DepositPaymentModalProps) {
	const [step, setStep] = useState(1);
	const [clientSecret, setClientSecret] = useState<string>();

	useEffect(() => {
		if (step === 2 && !clientSecret) {
			Puddleglum.Controllers.Employer.DepositController.createPaymentIntent()
				.then(({data}) => {
					setClientSecret(data.client_secret);
				})
				.catch(console.error);
		}
	}, [step]);

	const totalDeposit = depositAmount / 100;

	return (
		<Modal size='lg' isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Make Your Deposit</ModalHeader>
				<ModalBody>
					<VStack spacing={4} align='stretch'>
						<Stepper index={step - 1} size='sm'>
							<Step>
								<StepIndicator>
									<StepStatus
										complete={<StepIcon />}
										incomplete={<StepNumber />}
										active={<StepNumber />}
									/>
								</StepIndicator>
								<Box flexShrink='0'>
									<StepTitle>Review</StepTitle>
									<StepDescription>Review deposit amount</StepDescription>
								</Box>
								<StepSeparator />
							</Step>
							<Step>
								<StepIndicator>
									<StepStatus
										complete={<StepIcon />}
										incomplete={<StepNumber />}
										active={<StepNumber />}
									/>
								</StepIndicator>
								<Box flexShrink='0'>
									<StepTitle>Payment</StepTitle>
									<StepDescription>Enter payment details</StepDescription>
								</Box>
								<StepSeparator />
							</Step>
						</Stepper>

						{step === 1 && (
							<Box>
								<Text fontSize='sm' color='gray.600'>
									Your initial deposit amount is ${totalDeposit.toFixed(2)}. This
									amount was calculated according to your number of employees.
									<br />
									<br />
									If you cancel your membership, this deposit will be refunded.
								</Text>
								<Button mt={4} colorScheme='blue' onClick={() => setStep(2)}>
									Continue to Payment
								</Button>
								<ModalFooter px={0}>
									<Button variant='outline' onClick={onClose}>
										Cancel
									</Button>
								</ModalFooter>
							</Box>
						)}

						{step === 2 && clientSecret && (
							<Box>
								<Text fontSize='lg' fontWeight='bold' mb={2}>
									Enter Payment Details
								</Text>
								<Elements
									stripe={stripePromise}
									options={{
										clientSecret,
										appearance: {theme: 'stripe'},
									}}
								>
									<CheckoutForm onBack={() => setStep(1)} />
								</Elements>
							</Box>
						)}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
